import Modal from 'modal-vanilla';

const cancelSubscription = () => {
  const buttons = document.getElementsByClassName('js-unsubscribe-modal-opener');

  if (!buttons.length) return;

  [...buttons].forEach((button) => {
    button.addEventListener('click', () => {
      const modalEntity = document.getElementById('unsubModal');
      new Modal({ el: modalEntity }).show();
      const unsubLink = modalEntity.querySelector('.js-unsub-link');
      unsubLink.href = button.dataset.url;
    });
  });
};

export default cancelSubscription;
